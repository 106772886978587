import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import arrayIncludes from 'core-js/stable/array/virtual/includes';
import arrayFind from 'core-js/stable/array/virtual/find';
import stringIncludes from 'core-js/stable/string/virtual/includes';
import 'core-js/es/object';
import 'promise-polyfill/src/polyfill';
import 'abortcontroller-polyfill/dist/polyfill-patch-fetch';

Array.prototype.includes = arrayIncludes;
Array.prototype.find = arrayFind;
String.prototype.includes = stringIncludes;
